<template>
  <div id="vipTrip">
    <div class="header_top">
      <img src="@/assets/vipTrip/1@2x.png" alt="">
      <div class="kaiTong">
        <img src="@/assets/vipTrip/立即开通@2x.png" alt="">
      </div>
    </div>
    <div class="boJin">
      <img src="@/assets/vipTrip/本地生活铂金会员@2x.png" alt="">
      <div class="kaiTong">
        <img src="@/assets/vipTrip/立即开通@2x(1).png" alt="">
      </div>
    </div>
    <div class="heZuo">
      <img src="@/assets/vipTrip/本地合作商户@2x.png" alt="">
    </div>
    <div class="shuoMIng">
      <div class="text-wrapper_5">
        <span class="text">活动对象</span>
        <span class="paragraph">： 移动在网移动用户<br /></span>
        <span class="text">活动办理：</span>
        <span class="paragraph"> 移动营业厅/中国 移动APP<br /></span>
        <span class="text">活动规则：</span>
        <span class="paragraph"><br />一、 移动生活会员订购立即生效，协议期12个月，协议期内不可退订;<br />二、同一号码不可重复订购;<br />三、黄金会员、铂金会员仅可选择其中一个进行订购,不可叠加;<br />四、10元商超代金券两张和互联网会员权益仅可选择其中一项进行领取使用;，<br />五、互联网会员权益每月只可选其中一项领取并使用;&nbsp;<br />六、如果当月未及时领取相应权益，次月不得补领。如用户未按照界面展示期限使用，逾期无法使用。<br /></span>
        <span class="text">权益领取：</span>
        <span class="paragraph"><br />一、领取路径：登录“中 移动APP”，在首页“生活会员”宫格进入权益领取页。<br />二、领取商超券、充值券后需进行激活操作，激活后才可使用。自领取后需在15日内激活，激活后30天使用。如果过期未激活、过期未使用将不再补发，请及时激活并使用。<br />三、激活路径：在‘ 移动'微信公众号菜单，点击‘权益|福利-滨州生活’，在‘我的代金券'中找到要激活的代金券，点击‘激活'按钮即可将代金券激活。<br />四、领取互联网会员权益券后，需使用领券手机号登录三方应用进行登录。否则无法享受其会员服务。</span>
      </div>
      <span class="text2">因疫情、自然灾害等不可抗力原因,，造成合作门店、互联网合作商等暂停营业或关闭，以合作商户实际情况为准，中国 移动不再另行通知。</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  methods: {

  }

}
</script>

<style lang="less" scoped>
#vipTrip {
  background-color: #ffe2cf;
  padding-bottom: 20px;
}

img {
  width: 100%;
  height: auto;
}

.header_top {
  position: relative;

  .kaiTong {
    position: absolute;
    position: absolute;
    bottom: 50px;
    width: 67%;
    left: 70px;
  }
}

.boJin {
  position: relative;

  .kaiTong {
    position: absolute;
    position: absolute;
    bottom: 30px;
    width: 66%;
    left: 72px;
  }
}

.shuoMIng {
  background-image: url("~@/assets/vipTrip/活动说明@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  font-size: 15px;
  color: #555555;
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;

  .text {
    font-weight: 600;
  }

  .text2 {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    line-height: 18px;
    margin-bottom: 20px;
  }

  .paragraph {
    font-weight: 400;
  }
}
</style>